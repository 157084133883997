import * as Sentry from "@sentry/nextjs";

import Error404 from "@/components/404";
import Error500 from "@/components/500";

import { getCTASportbooks404Shortcode } from "./api/apiWP";

const Error = ({ statusCode, shortcode, path, error }) => {
  if (statusCode === 404) {
    return <Error404 shortcode={shortcode} />;
  }
  if (
    statusCode === 500 &&
    (path.includes("matchup") || path.includes("rivalry"))
  ) {
    return <Error404 shortcode={shortcode} />;
  }

  return <Error500 error={error} />;
};

Error.getInitialProps = async (context) => {
  const { res, err, asPath } = context;
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  const path = asPath || "";
  const imageTypeRegex = /\.(jpg|jpeg|png|gif|bmp|svg|webp)/gi;

  const a = await Sentry.captureUnderscoreErrorException(context);

  if (res && path.match(imageTypeRegex)) {
    const FRONTEND_URL = process.env.NEXT_PUBLIC_BETTING_NEWS_FRONTEND_URL;
    res.writeHead(302, {
      Location: `${FRONTEND_URL}/_next/image/?url=https%3A%2F%2Fwp.bettingnews.com%2Fwp-content%2Fuploads%2F2023%2F07%2Ffallback-image.jpg&w=3840&q=75`,
    });
    res.end();
    return {};
  }

  const shortcode = await getCTASportbooks404Shortcode();

  return {
    statusCode,
    error: err,
    shortcode: shortcode || null,
    path,
  };
};

export default Error;
