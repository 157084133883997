import * as Sentry from "@sentry/nextjs";
import Error from "next/error";

import Head from "@/components/Head";
import { IS_PROD } from "@/config/constants";
import labels from "@/config/labels";

const Error500 = ({ error }) => (
  <>
    <Head seo={{ title: labels["500HeadTitle"] }} />
    <h1 className="mb-8 text-center font-heading text-2xl font-bold capitalize leading-[1.15] text-nav-blue sm:text-[2rem]">
      {labels["500Title"]}
    </h1>
    <p className="text-center">
      {IS_PROD ? (
        <>
          {labels["500Message"]}
          <span className="ml-2" classrole="img" aria-label="confused face">
            😕
          </span>
        </>
      ) : (
        error?.stack || error?.message
      )}
    </p>
  </>
);

Error500.getInitialProps = async (contextData) => {
  await Sentry.captureUnderscoreErrorException(contextData);
  return Error.getInitialProps(contextData);
};

export default Error500;
