import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import Link from "next/link";

import Head from "@/components/Head";
import labels from "@/config/labels";
import ShortCodes from "@/utilities/shortcodes";

const Error404 = ({ shortcode }) => {
  return (
    <>
      <Head seo={{ title: labels["404HeadTitle"] }} />
      <ShortCodes />
      <div id="bettingnews-content">
        <h1 className="text-center">
          <span className="text-danger">{labels["404TitleOops"]}</span>{" "}
          {labels["404TitleNotFound"]}
        </h1>
        <p className="text-center">{labels["404Subtitle"]}</p>
        <div>
          <div className="text-center">
            <Link href="/" className="btn btn-primary btn-lg">
              {labels["404CTA"]}
            </Link>
          </div>
          {shortcode && (
            <>
              <h2 className="text-center">{labels["404ShortcodeLabel"]}:</h2>
              <div dangerouslySetInnerHTML={{ __html: shortcode }} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

Error404.getInitialProps = async (contextData) => {
  await Sentry.captureUnderscoreErrorException(contextData);
  return Error.getInitialProps(contextData);
};

export default Error404;
